import create from "zustand";

import { errorAwareApiFetch } from "../../../apiBindings";
import {
    deleteUrlParam,
    getUrlParam,
    setUrlParam,
} from "../../../utils/urlParams";

export type iCategory = {
    id: number;
    name: string;
    products?: iProduct[];
    parentId: number | null;
    parent: null | iCategory;
    children: iCategory[] | [];
    createdAt: string;
    updatedAt: string;
};

export type iProduct = {
    id: number;
    productNumber: string;
    name: string;
    finkeId: string;
    shortDescription: string;
    imageUrl: string;
    description: string;
    category?: iCategory;
    createdAt: string;
    updatedAt: string;
};

type iPaginationLinks = {
    first: string;
    last: string;
    next: string | null;
    prev: string | null;
};

type iPaginationMeta = {
    current_page: number;
    last_page: number;
    from: number;
    to: number;
    total: number;
    per_page: number;
    links: [
        {
            url: string;
            active: boolean;
            label: string;
        }
    ];
};

export enum SearchFilter {
    ALLES = "all",
    NACH_FAHRZEUG = "car",
    NACH_CATEGORY = "category",
    NACH_SCHLUESSELNUMMER = "key_number",
}

export type iPaginateEnvelope<T> = {
    data: T;
    links: iPaginationLinks;
    meta: iPaginationMeta;
};

export type iCategoryResponse<T> = {
    data: T;
};

interface iOilFinderStore {
    loading: boolean;
    loadingSearch: boolean;
    categories: iCategory[] | [];
    products: iProduct[] | [];
    page: number;
    category: iCategory | null;
    parent: number | null;
    search: string | null;
    paginationMeta: iPaginationMeta | null;
    paginationLinks: iPaginationLinks | null;
    searchFilter: SearchFilter;
    needsReload: boolean;
    getData: () => void;
    setPage: (page: number) => void;
    setCategory: (category: iCategory | null) => void;
    setSearch: (search: string) => void;
    setSearchFilter: (value: SearchFilter) => void;
    searchResults: any;
    getSearchResults: () => void;
    setSearchResults: (value: any) => void;
    vehicle: null | any;
    setVehicle: (value: any | null) => void;
    clearSearch: () => void;
    handleNavigation: (category: iCategory) => void;
    savedProduct: iProduct | null;
    setSavedProduct: (savedProduct: iProduct) => void;
    setNeedsReload: (value: boolean) => void;
}

type iApiResponse = {
    categories: iCategoryResponse<Array<iCategory>>;
    products: iPaginateEnvelope<Array<iProduct>>;
    category: iCategory;
};

type iSearchApiResponse = {
    searchResults: any;
};

const pageParam = getUrlParam("page");
const categoryParam = getUrlParam("category");
const searchParam = getUrlParam("search");
const page = pageParam !== null ? parseInt(pageParam) : 1;

const useStore = create<iOilFinderStore>((set, get) => ({
    loading: false,
    loadingSearch: false,
    categories: [],
    products: [],
    page: page,
    category: null,
    parent: null,
    search: searchParam ?? null,
    paginationMeta: null,
    paginationLinks: null,
    searchFilter: SearchFilter.ALLES,
    searchResults: null,
    vehicle: null,
    needsReload: true,
    savedProduct: null,

    getData: () => {
        set({ loading: true, needsReload: false });
        const data = get();
        const page = data.page;
        const search = data.search;
        const searchFilter = data.searchFilter;
        const category = data.category;
        const vehicleId = data?.vehicle?.id ?? "";

        errorAwareApiFetch<iApiResponse>(
            `/oil-finder?page=${page}${
                category || getUrlParam("category")
                    ? `&category=${category?.id ?? getUrlParam("category")}`
                    : ""
            }${search ? `&search=${search}` : ""}${
                searchFilter ? `&searchFilter=${searchFilter}` : ""
            }${vehicleId ? `&vehicleId=${vehicleId}` : ""}`,
            {
                method: "GET",
            }
        )
            .then((response) => {
                const categories = response.categories.data;
                let tempCategory: null | iCategory = categories[0];
                if (
                    (search || searchParam) &&
                    searchFilter !== SearchFilter.NACH_CATEGORY
                ) {
                    tempCategory = null;
                } else if (response.category) {
                    tempCategory = response.category;
                }
                set({
                    categories: response.categories.data,
                    products: response.products.data,
                    paginationMeta: response.products.meta,
                    paginationLinks: response.products.links,
                    category: tempCategory,
                    parent: tempCategory?.parentId ?? null,
                });
            })
            .catch((errors) => {
                console.log(errors);
            })
            .finally(() => set({ loading: false }));
    },

    getSearchResults: () => {
        set({ loadingSearch: true, searchResults: null, vehicle: null });
        const data = get();
        const searchFilter = data.searchFilter;
        const search = data.search;
        errorAwareApiFetch<iSearchApiResponse>(
            `/oil-finder/search/?searchFilter=${searchFilter}&search=${search}`,
            {
                method: "GET",
            }
        )
            .then((response) => {
                set({
                    searchResults: response.searchResults,
                });
            })
            .catch((errors) => {
                console.log(errors);
            })
            .finally(() => set({ loadingSearch: false }));
    },

    setPage: (page) => {
        set({ page });
    },

    setCategory: (category) => {
        set({ category });
    },

    setSearch: (search) => {
        set({ search });
    },

    setSearchFilter: (value) => {
        set({
            searchFilter: value,
        });
    },

    setSearchResults: (value) => {
        set({
            searchResults: value,
        });
    },

    setVehicle: (value) => {
        set({
            vehicle: value,
        });
    },

    clearSearch: () => {
        const data = get();
        const category = data.category ?? data.categories[0];
        set({
            search: "",
            searchResults: null,
            vehicle: null,
            category: category,
            needsReload: true,
        });
    },

    handleNavigation: (category) => {
        const data = get();
        const isCategorySearch =
            data.searchFilter === SearchFilter.NACH_CATEGORY;
        const search = data.search;
        setUrlParam("category", category.id.toString());
        setUrlParam("page", "1");
        deleteUrlParam("search");
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        set({
            search: isCategorySearch ? search : "",
            searchFilter: SearchFilter.NACH_CATEGORY,
            searchResults: null,
            vehicle: null,
            category: category,
            parent: category?.parentId ?? null,
            needsReload: true,
            page: 1,
        });
    },

    setSavedProduct: (savedProduct) => {
        set({
            savedProduct: savedProduct,
        });
    },

    setNeedsReload: (value) => {
        set({ needsReload: value });
    },
}));

export default useStore;
